<template>
  <section id='dashboard-full'>
    <b-row class='match-height'>
      <b-col xl='12' md='12'>
        <UserStatistics v-if="statData.length > 0" :data='statData[0].statisticsItems' />
      </b-col>
    </b-row>
    <b-row class='match-height'>
      <b-col lg='6'>
        <b-row class='match-height'>
          <b-col lg='6' md='3' cols='6'>
            <StatisticsOfBoyChart v-if="statData.length > 0"  :gender-list='genderMaleList'  :stat-item='statData[0].statisticsGender' />
          </b-col>
          <b-col lg='6' md='3' cols='6'>
            <StatisticsOfGirlsChart v-if="statData.length > 0"   :gender-list='genderFemaleList' :stat-item='statData[0].statisticsGender' />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg='6'>
        <ChatBotStatistics v-if="statData.length > 0"  :data='statData[0].earningsChart' />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols='12'>
        <MonthlyStatistics v-if="statData.length >0" @selectedYear='handleYear'  :data='statData[0].revenue' />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import UserStatistics from './UserStatistics.vue'
import StatisticsOfBoyChart from './StatisticsOfBoyChart.vue'
import MonthlyStatistics from './MonthlyStatistics.vue'
import ChatBotStatistics from './ChatBotStatistics.vue'
import StatisticsOfGirlsChart from './StatisticsOfGirlsChart.vue'

export default {
  components: {
    MonthlyStatistics,
    ChatBotStatistics,
    BRow,
    BCol,
    UserStatistics,
    StatisticsOfBoyChart,
    StatisticsOfGirlsChart,
  },
  data() {
    return {
      statData: [],
      genderMaleList:[],
      genderFemaleList:[],
      selectedYear: 2024,
    }
  },
  created() {
    this.$http.get('/stat/list').then(response => {
      this.statData.push(response.data)
      const userData = getUserData()
      this.statData[0].congratulations.name = userData.username || userData.email
    })
    this.getStatItems(this.selectedYear)
    this.getGenderBarData(this.selectedYear)
  },
  methods: {
    getStatItems(year) {
      this.$http.get(`/stat/main-numbers/${year}`).then(res => {
        this.statData[0].statisticsItems.forEach(item => {
          item.title = res.data[item.type];
        })
        this.statData[0].statisticsGender.forEach(item => {
          item.title = res.data[item.type];
        })
      })
    },
    handleYear(year){
      this.selectedYear = year
      this.getGenderBarData(year);
      this.getStatItems(year);
      // this.getGenderStat(year);
    },
    getGenderBarData(year){
      this.genderMaleList = [];
      this.genderFemaleList = [];
      this.$http.get(`/stat/child-list-by-month/${year}`).then(res => {
        const dataMale = {
          data:[]
        };
        const dataFemale = {
          data:[]
        }
        res.data.forEach(item => {
          dataMale.data[item.month - 1] = item.countMale;
          dataFemale.data[item.month - 1] = item.countFemale;
        });
        this.genderMaleList.push(dataMale);
        this.genderFemaleList.push(dataFemale);
      });
    }
  }
}
</script>

<style lang='scss'>
@import '@core/scss/vue/pages/dashboard-full.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
