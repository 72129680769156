<template>
  <b-card v-if='data' no-body class='card-revenue-budget'>
    <b-row class='mx-0'>
      <b-col md='8' class='revenue-report-wrapper'>
        <div class='d-sm-flex justify-content-between align-items-center mb-3'>
          <h4 class='card-title mb-50 mb-sm-0'>
            Oy kesimida bolalar statistikasi
          </h4>
          <div class='d-flex align-items-center'>
            <div class='d-flex align-items-center mr-2'>
              <span
                class='bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer'
              />
              <span>O'g'il bolalar</span>
            </div>
            <div class='d-flex align-items-center ml-75'>
              <span
                class='bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer'
              />
              <span>Qiz bolalar</span>
            </div>
          </div>
        </div>
        <vue-apex-charts
          id='revenue-report-chart'
          type='bar'
          height='230'
          :options='revenueReport.chartOptions'
          :series=' revenueReport.series'
        />
      </b-col>

      <b-col md='4' class='budget-wrapper'>
        <b-dropdown
          v-model="selectedYear"
          :text='selectedYear'
          class='budget-dropdown'
          variant='outline-primary'
        >
          <b-dropdown-item @click='handleChange(year)' v-for='year in data.years' :key='year' :value='year'>
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>

        <h2 class='mb-25'>
          {{ this.countChildren }}
        </h2>
        <div class='d-flex justify-content-center'>
          <span class='font-weight-bolder mr-25'>Jami (nafar)</span>
          <!--          <span>56,800</span>-->
        </div>
        <vue-apex-charts
          id='budget-chart'
          type='line'
          height='80'
          :options='budgetChart.options'
          :series='budgetChart.series'
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol
  },
  directives: {
    Ripple
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    genderList:{
      type: Array,
      default:()=>[]
    }
  },
  data() {
    return {
      selectedYear: '2024',
      countChildren:0,
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false }
          },
          labels:['Ogil bolalar','Qiz bolalar'],
          grid: {
            padding: {
              top: -20,
              bottom: -10
            },
            yaxis: {
              lines: { show: false }
            }
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat'
              }
            },
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: false
            }
          },
          legend: {
            show: false
          },
          dataLabels: {
            enabled: false
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '30%',
              // endingShape: 'rounded'
            },
            distributed: true
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat'
              }
            }
          }
        },
        series: []
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true }
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2]
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false
          }
        },
        series: []
      }
    }
  },
  methods: {
    getChildListByMonth() {
      this.revenueReport.series =[];
      this.$http.get(`/stat/child-list-by-month/${this.selectedYear}`).then(res => {
        res.data.forEach(item => {
          const dataMale = {
            data: []
          }
          const dataFemale = {
            data: []
          }
          dataMale.data[item.month - 1] = item.countMale;
          this.revenueReport.series.push(dataMale);
          dataFemale.data[item.month - 1] = item.countFemale;
          this.revenueReport.series.push(dataFemale);
        })
      })
    },
    getChildrenByStatFullDataByYear() {
      this.$http.get(`/stat/child-list-annually/${this.selectedYear}`).then(res => {
        res.data.forEach(item => {
          const dataFull = {
            data: []
          }
          this.countChildren+=item.count;
          dataFull.data[item.month - 1] = item.count;
          this.budgetChart.series.push(dataFull);
        })
      })
    },
    handleChange(selectedYear){
      this.countChildren =0;
      this.revenueReport.series =[];
      this.budgetChart.series = [];
      this.selectedYear =selectedYear;
      this.$emit('selectedYear',parseInt(selectedYear));
      this.getChildListByMonth();
      this.getChildrenByStatFullDataByYear();
    }
  },
  created() {
    this.getChildListByMonth();
    this.getChildrenByStatFullDataByYear();
  }
}
</script>
